<template>
  <div>
    <a-card>
      <div class="ffr">
        <a-button style="margin: 16px" @click="createNew" type="primary">新建</a-button>
      </div>
      <a-table
        rowKey="id"
        :pagination="pagination"
        :columns="columns"
        :dataSource="data"
        @change="changePageSize"
        style="margin-bottom: 24px"
      >
        <span slot="action" slot-scope="text, record" class="blueColor">
          <a @click="handleEdit(record)">修改</a>
        </span>
      </a-table>
    </a-card>
    <!-- 编辑卡片 -->
    <a-modal
      title="签约主体配置"
      :width="700"
      :visible="isEdit"
      :confirmLoading="editLoading"
      destroyOnClose
      @ok="handleSaveEdit"
      @cancel="handleCancelEdit"
    >
      <a-form layout="inline" :form="editForm">
        <a-row :gutter="30" style="line-height: 4">
          <a-col :md="24" :sm="24">
            <a-form-item label="公司主体名称" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入'
                      }
                    ],
                    initialValue: editObj.name
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="统一社会信用代码" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'usc',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入'
                      }
                    ],
                    initialValue: editObj.usc
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司联系地址" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'address',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入'
                      }
                    ],
                    initialValue: editObj.address
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司联系人" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'contact',
                  { 
                    initialValue: editObj.contact
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司联系人电话" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'phone',
                  { 
                    initialValue: editObj.phone
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司联系人电子邮箱" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'email',
                  { 
                    initialValue: editObj.email
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司账户名称" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'accountName',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入'
                      }
                    ],
                    initialValue: editObj.accountName
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司账户开户行" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'accountBank',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入'
                      }
                    ],
                    initialValue: editObj.accountBank
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司账号" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'account',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入'
                      }
                    ],
                    initialValue: editObj.account
                  }
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <!-- 新建卡片 -->
    <a-modal
      title="新建主体配置"
      :width="700"
      :visible="isCreate"
      :confirmLoading="createLoading"
      destroyOnClose
      @ok="handleSaveCreate"
      @cancel="handleCancelCreate"
    >
      <a-form layout="inline" :form="createForm">
        <a-row :gutter="30" style="line-height: 4">
          <a-col :md="24" :sm="24">
            <a-form-item label="公司主体名称" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入'
                      }
                    ],
                    initialValue: createObj.name
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="统一社会信用代码" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'usc',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入'
                      }
                    ],
                    initialValue: createObj.usc
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司联系地址" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'address',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入'
                      }
                    ],
                    initialValue: createObj.address
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司联系人" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'contact',
                  { }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司联系人电话" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'phone',
                  { }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司联系人电子邮箱" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'email',
                  { }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司账户名称" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'accountName',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入'
                      }
                    ],
                    initialValue: createObj.accountName
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司账户开户行" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'accountBank',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入'
                      }
                    ],
                    initialValue: createObj.accountBank
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="公司账号" :labelCol="formLabelCol">
              <a-input
                type="text"
                style="width: 400px;"
                placeholder="请输入"
                v-decorator="[
                  'account',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入'
                      }
                    ],
                    initialValue: createObj.account
                  }
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  

import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
export default {
  name: "companyMainConfig",
  data() {
    return {
      pagination: defaultPagination(() => {}),
      data: [],
      // 表头
      columns: [
        {
          title: "公司主体名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "统一社会信用代码",
          dataIndex: "usc",
          key: "usc",
        },
        {
          title: "操作",
          key: "action",
          width: "200px",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      current: 1,
      pageSize: 20,
      // 编辑
      isEdit: false,
      editLoading: false,
      // editObj
      editObj: {},
      // 新建
      isCreate: false,
      createLoading: false,
      // createObj
      createObj: {},
      editForm: this.$form.createForm(this),
      createForm: this.$form.createForm(this),
      // 表格中文本的样式
      formLabelCol: {
        style: {
          textAlign: "left",
          width: "140px",
        },
      },
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", (target) => {
      this.init();
    });
  },
  methods: {
    changePageSize(val) {
      this.current = val.current;
      this.pageSize = val.pageSize;
      this.init(this.current, this.pageSize);
    },
    init(current, pageSize) {
      // 查询公司主体列表
      // /signEntity/listCompanyEntity
      // 参数：name-公司主体名称、usc-企业统一社会信用代码、page、rows
      axios({
        url:   this.$baseUrl + "signEntity/listCompanyEntity",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
        },
      })
        .then((res) => {
          if (res.data.returnCode == "200") {
            this.data = res.data.rows;
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    // handleCancelEdit
    handleCancelEdit() {
      this.isEdit = false;
    },
    // handleEdit
    handleEdit(info) {
      this.editObj = {};
      this.editObj = info;
      this.isEdit = true;
    },
    // handleSaveEdit
    handleSaveEdit() {
      let that = this;
      const {
        editForm: { validateFields },
      } = this;
      validateFields((errors, values) => {
        // if (!errors) {
          that.editObj = Object.assign(that.editObj, values);
        // }
      });
      if (!this.editObj.name) {
        this.$message.error("公司主体名称有误！");
        this.editLoading = false;
        return;
      }
      if (!this.editObj.usc) {
        this.$message.error("统一社会信用代码有误！");
        this.editLoading = false;
        return;
      }
      if (!this.editObj.address) {
        this.$message.error("公司联系地址有误！");
        this.editLoading = false;
        return;
      }
      if (!this.editObj.accountName) {
        this.$message.error("公司账户名称有误！");
        this.editLoading = false;
        return;
      }
      if (!this.editObj.accountBank) {
        this.$message.error("公司账户开户行有误！");
        this.editLoading = false;
        return;
      }
      if (!this.editObj.account) {
        this.$message.error("公司账号有误！");
        this.editLoading = false;
        return;
      }
      axios({
        url:   this.$baseUrl + "signEntity/updateCompanyEntity",
        method: "POST",
        data: {
          ...this.editObj,
        },
      })
        .then((res) => {
          if (res.data.returnCode == "200") {
            this.$message.info(`${res.data.returnMsg}`);
            this.init(this.current, this.pageSize);
            this.editLoading = false;
            this.handleCancelEdit();
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch((error) => {
          this.editLoading = false;
          this.$message.error(error.message);
        });
    },
    // createNew
    createNew() {
      this.createObj = {};
      this.isCreate = true;
    },
    // handleCancelCreate
    handleCancelCreate() {
      this.isCreate = false;
    },
    // handleSaveCreate
    handleSaveCreate() {
      let that = this;
      const {
        createForm: { validateFields },
      } = this;
      validateFields((errors, values) => {
        // if (!errors) {
          that.createObj = values;
        // }
      });
      this.createLoading = true;
      if (!this.createObj.name) {
        this.$message.error("公司主体名称有误！");
        this.createLoading = false;
        return;
      }
      if (!this.createObj.usc) {
        this.$message.error("统一社会信用代码有误！");
        this.createLoading = false;
        return;
      }
      if (!this.createObj.address) {
        this.$message.error("公司联系地址有误！");
        this.createLoading = false;
        return;
      }
      if (!this.createObj.accountName) {
        this.$message.error("公司账户名称有误！");
        this.createLoading = false;
        return;
      }
      if (!this.createObj.accountBank) {
        this.$message.error("公司账户开户行有误！");
        this.createLoading = false;
        return;
      }
      if (!this.createObj.account) {
        this.$message.error("公司账号有误！");
        this.createLoading = false;
        return;
      }
      axios({
        url:   this.$baseUrl + "signEntity/createCompanyEntity",
        method: "POST",
        data: {
          ...this.createObj,
        },
      })
        .then((res) => {
          if (res.data.returnCode == "200") {
            this.$message.info(`${res.data.returnMsg}`);
            this.init(1, 20);
            this.createLoading = false;
            this.handleCancelCreate();
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch((error) => {
          this.createLoading = false;
          this.$message.error(error.message);
        });
    },
  },
};
</script>

<style scoped>
.ffr {
  display: flex;
  justify-content: flex-end;
}
</style>
